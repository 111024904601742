body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  position: fixed;
  top: 50%;
  left: 50%;
  font-size: 60px;
  transform: translate(-50%, -50%);
  font-family: 'Roboto', sans-serif;
  color: white;
}

h2 {
  position: fixed;
  top: 60%;
  left: 50%;
  font-size: 45px;
  transform: translate(-50%, -50%);
  font-family: 'Roboto', sans-serif;
  color: white;
}

button {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  margin-left: 2%;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 30px;
}

span {
  color: #f2c40d;
}

body {
  margin: 0;
}

*{
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
 }