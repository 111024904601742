input[type=text], select, textarea {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */ 
  border: 1px solid #ccc; /* Gray border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
}

/* Style the submit button with a specific background color etc */
input[type=submit] {
  background-color: #04AA6D;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: table;
  margin-left: auto;
  margin-right: auto;
  width: 15%;
}

/* When moving the mouse over the submit button, add a darker green color */
input[type=submit]:hover {
  background-color: #45a049;
}




input.subject {
  background-color: #f2f2f2;
  width: 30%;
  display: table;
  margin-left: auto;
  margin-right: auto;

}

textarea.subject {
  background-color: #f2f2f2;
  width: 30%;
  height: 250px;
  display: table;
  margin-left: auto;
  margin-right: auto;
}

label.field-names {
    font-size: 20px;
    font-weight: bold;
    display: table;
    margin-left: auto;
    margin-right: auto;
}

p.contactme-header {
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2%;
    margin-top: 1%;
}
