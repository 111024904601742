
.App {
    text-align: center;
    position: fixed;
    min-width: 100%;
    min-height: 100%;
    background-size: cover;
    background-position: center;
    background-color: white;
    margin: 0;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  body {
    /* background-color:  #55BCC9; */
    background-color: #abe4ea;
  }

  body.home {
    margin: 0;
    background-color: white;
  }
  p.education {
    color: black;   
    font-size: 30px;
    margin-left: 10%;
    margin-top: 5%;
    font-family: 'Roboto Condensed', sans-serif;
  }

  p.education-bachelor {
    text-align: center;
    font-style: italic;
  }

  p.education-graduation {
    text-align: center;
    font-style: italic;
  }
  p.left {
   color: black;   
   font-weight: bold;
   font-size: 30px;
   margin-left: 10%;
   margin-top: 3%;
  }

  p.education-text {
      color: black;
      margin-left: 13%;
  }

  p.work {
    color: black;   
    
    font-size: 30px;
    margin-left: 10%;
    margin-top: 3%;
    font-family: 'Roboto Condensed', sans-serif;
  }

  p.work-title {
    text-align: center;
    font-style: italic;
  }

  p.work-text {
    display: table;
    margin-left: 30%;
  }
  p.skill {
    color: black;   
    
    font-size: 30px;
    margin-left: 10%;
    margin-top: 3%;
    font-family: 'Roboto Condensed', sans-serif;
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 3px solid #008b8b;
    margin: 1em 0;
    padding: 0;
    width: 80%;
    margin-left: 10%;
}
  span.resume {
    color: black;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    margin: auto;
    display: inline-block;
  }

  p.resume {
    color: black;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    margin: auto;
    text-align: center;
    font-size: 30px;
  }

  ul.education-text {
    margin-left: 30%;
  }

  ul.work-text {
    /*list-style-type: circle;*/
    margin-left: 30%;
  }

  ul.experiences li {
    position:relative; /* so that pseudoelements are positioned relatively to their "li"s*/
    /* use padding-bottom instead of margin-bottom.*/ 
    margin-bottom: 0; /* This overrides previously specified margin-bottom */
    padding-bottom: 2.5em;
}

/* https://stackoverflow.com/questions/41845953/vertical-line-with-dots-in-ends-and-between*/
.bar {
  list-style: none;
}
.bar >li {
  position: relative;
}
.bar>li:before {
  content: '\25CF';
  margin-right: 10px;
  font-size: 20px;
}
.bar>li:after {
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  border-left: 2px solid black;
  margin-left: 5px;
  height: 100%;
}
.bar >li:first-of-type:after {
  top: 50%;
}
.bar >li:last-of-type:after {
  top: -50%;
}

.multiline
{
   white-space: pre-wrap;
}

.btn {
  
  background-color: DodgerBlue;
  border: none;
  color: white;
  padding: 10px 60px;
  cursor: pointer;
  font-size: 30px;
  margin-top: -200px;
  display: block;
  margin: auto auto;
  width: 10%;
  text-decoration: none;
  font-weight: bold;
}

@media screen and (max-width: 960px) {
  .btn {
  
    background-color: DodgerBlue;
    border: none;
    color: white;
    padding: 10px 60px;
    cursor: pointer;
    font-size: 30px;
    margin-top: -200px;
    display: block;
    margin: auto auto;
    width: 25%;
    text-decoration: none;
    font-weight: bold;
  }
}

.vert-align {
  margin-top: 3%;
}

body[data-aos-delay='4000'] [data-aos],
[data-aos][data-aos][data-aos-delay='4000'] {
  transition-delay: 4000ms;
}

p.resume-header{
  color: black;
  font-size: 60px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 3%;
}

.parent{
  display: flex;
  justify-content: center;
  align-items: center;
}

ul.box{
  background-color: #f4e7d4;
  width: 1050px;
  border: 3px solid gray;
  padding: 40px;
  margin-left: auto;
  margin-right: auto;
}

div.work {
  border: 3px solid gray;
  padding: 40px;
  margin-left: auto;
  margin-right: auto;
  width: 1050px;
  background-color: white;
}

p.work-title {
  font-size: 35px;
  font-weight: bold;
}

p.work-company {
  margin-top: 3%;
  font-size: 20px;
  font-family: Arial Black ;
}

p.work-position {
  margin-bottom: 1%;
  font-family: Arial;
  font-style: italic;
  font-size: 18px;

}
span.work-title {
  color: grey;
  font-family: Arial;
}

div.skill {
  border: 3px solid gray;
  padding: 40px;
  margin-left: auto;
  margin-right: auto;
  width: 1050px;
  background-color: white;
  margin-bottom: 5%;
}

p.work-skillstitle {
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 5%;
}

div.skill-name {
  margin-bottom: 1%;
  font-family: Helveticas ;
  font-size: 18px;
  font-weight: bold;

}

ul.resumepoints {
  margin-left: 2%;
}